import React from "react";
import "./testimonials.css";
import abdullah_munir from "../../assets/avatars/abdullah_munir.png";
import Mehmet_Furkan_Turkcanoglu from "../../assets/avatars/Mehmet_Furkan_Turkcanoglu.png";
import serhat_goren from "../../assets/avatars/serhat_goren.png";
import Mert_ustun from "../../assets/avatars/Mert_ustun.png";
import Muharrem_Koroğlu from "../../assets/avatars/Muharrem_Koroğlu.png";

// import required modules
import { Pagination } from "swiper";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const data = [
  {
    avatar: serhat_goren,
    name: "Serhat",
    review:
      "I had the pleasure of working with Ramazan on an extensive project and Ramazan is very tidy, structured and keen to find good solutions for problems. For me, he has been a good partner and I have benefited a lot from discussing with him.",
    linkedin: "https://www.linkedin.com/in/serhat-goren/",
    github: "",
  },

  {
    avatar: Mehmet_Furkan_Turkcanoglu,
    name: "Furkan",
    review:
      "We worked closely in a team over a periode of four months, and I couldn't have asked for a better partner! Ramazan is extremely dedicated, and always askes the right questions in order to be on the right track.",
    linkedin: "https://www.linkedin.com/in/mehmet-furkan-turkcanoglu/",
    github: "https://github.com/mfturkcanoglu",
  },
  {
    avatar: Mert_ustun,
    name: "Mert",
    review:
      "I worked with Ramazan on a challenging trend analysis project. I would describe him as solution-oriented and adaptable. Last but not least, he is creative, good at design, and a great collaborator!",
    linkedin: "https://www.linkedin.com/in/mertusstunn/",
    github: "https://github.com/mertustun",
  },
  {
    avatar: Muharrem_Koroğlu,
    name: "Muharrem",
    review:
      "I had the pleasure of working with Ramazan on a very challenging project! He is good at finding simple and creative solutions to complex challenges. He is also very helpful, motivated and has great work capacity.",
    linkedin: "https://www.linkedin.com/in/muharremkoroglu/",
    github: "https://github.com/MuharremKoroglu",
  },
  {
    avatar: abdullah_munir,
    name: "Abdullah",
    review:
      "Ramazan comes up with ideas easily, and his ability to question the status quo is impressive. In our daily work, he drives our team not only with constant contribution but also with his visionary mind.",
    linkedin: "https://www.linkedin.com/in/abdullah-munir-3a54a8203/",
    github: "",
  },
];

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Reviews from coworkers</h5>
      <h2>Testimonials</h2>
      <Swiper
        className="container testiomonials__container"
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {data.map(({ avatar, name, review, linkedin, github }, index) => {
          return (
            <SwiperSlide key={index} className="testimonial">
              <div className="coworker-avatar">
                <img src={avatar} alt="Coworker" />
              </div>
              <h5 className="coworker__name">{name}</h5>
              <small className="coworker__review">{review}</small>
              <div className="testimonials__item">
                { github ?
                  <a
                    href={github}
                    target="_blank"
                    className="testimonials__btn"
                  >
                    Github
                  </a> : ""
                }
                <a
                  href={linkedin}
                  target="_blank"
                  className="testimonials__btn testimonials__btn-primary"
                >
                  LinkedIn
                </a>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Testimonials;
