import React from "react";
import "./about.css";
import profile_picture from "../../assets/myPhotos/me-color.jpeg";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h5>Get to learn</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={profile_picture} alt="About Ramazan image" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>2+ Years Dev XP</small> <br />
            </article>
            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Education</h5>
              <small>
                B.Sc. Computer Engineering / istanbul turkey
                <br />
                B.Sc. Computer Science / Lodz poland
              </small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>
                20+ Completed
                <br />
                and Counting
              </small>
            </article>
          </div>
          <p>
            As a web developer, I have a strong background in both front-end and
            back-end development, with experience in a variety of programming
            languages and frameworks including HTML, CSS, JavaScript,React.js Nodejs,
            MySQL, and many other frameworks. I have a degree in computer
            science from Uskudar University,and have completed several
            professional development courses in web development and related
            technologies. In my previous roles, I have had the opportunity to
            work on a variety of projects, ranging from small websites to
            large-scale web applications. I am highly skilled at problem-solving
            and have a strong attention to detail, which has helped me to
            deliver high-quality work on time and on budget. In addition to my
            technical skills, I am also a team player who enjoys working
            collaboratively with others to achieve common goals. I am excited to
            bring my skills and experience to a new opportunity as a web
            developer.
          </p>
          <p></p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
