import React from "react";
import "./portfolio.css";
import nereye from "../../assets/images/nereye.png";
import airbnbClone from "../../assets/images/airbnb-clone.png";
import blogAppVersion1 from "../../assets/images/blog-app-version-1.png";
import investmentCalculator from "../../assets/images/investment-calculator.png";
import realEstateApp from "../../assets/images/real-estate-app.png";
import trackExpenses from "../../assets/images/track-expenses.png";
import twitterClone from '../../assets/images/twitter-clone.png'
import discomfort from '../../assets/images/discomfort.png'
import dis from '../../assets/images/dis.png'

const data = [
  {
    id: 1,
    image: discomfort,
    title: "MERN Stack e-commerce website",
    github: "https://github.com/ramazangoren/discomfort-clothing",
    demo: "https://discomfort.astrainovations.com/",
  },
  {
    id: 2,
    image: dis,
    title: "MERN Stack food-ordering",
    github: "https://github.com/ramazangoren/mern-food-ordering-app",
    demo: "https://discomfort.astrainovations.com/",
  },
  {
    id: 3,
    image: nereye,
    title: "MERN Stack Restaurant platform nereye",
    github: "https://github.com/ramazangoren/nereye",
    demo: "https://nereye.astrainovations.com/",
  },
  {
    id: 4,
    image: airbnbClone,
    title: "airbnb clone MERN stack",
    github: "https://github.com/ramazangoren/airbnb-clone",
    demo: "https://airbnb-clone-r.netlify.app/",
  },
  {
    id: 5,
    image: twitterClone,
    title: "twitter Clone MERN stack",
    github: "https://github.com/ramazangoren/twitter-clone",
    demo: "https://twitter-clone-7fbx.onrender.com/",
  },
  {
    id: 6,
    image: blogAppVersion1,
    title: "blog-app-version-1 MERN stack",
    github: "https://github.com/ramazangoren/mern-responsive-blog-app",
    demo: "https://blog-app-version-1.netlify.app",
  },
  {
    id: 7,
    image: investmentCalculator,
    title: "investment-calculator MERN stack",
    github: "https://github.com/ramazangoren/investment-calculater",
    demo: "https://calculatorforinvestment.netlify.app",
  },
  {
    id: 8,
    image: realEstateApp,
    title: "real-estate-app MERN stack",
    github: "https://github.com/ramazangoren/mern-real-estate",
    demo: "https://real-estate-r.netlify.app",
  },
  {
    id: 9,
    image: trackExpenses,
    title: "track-expenses",
    github: "https://github.com/ramazangoren/track-expenses",
    demo: "https://trackexpenses-r.netlify.app",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My most recent work can be found on YouTube</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={github} target="_blank" className="btn">
                  Github
                </a>
                <a href={demo} target="_blank" className="btn btn-primary">
                  Live Demo
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
